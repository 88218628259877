import { render, staticRenderFns } from "./topay.vue?vue&type=template&id=18314800&scoped=true&"
import script from "./topay.vue?vue&type=script&lang=js&"
export * from "./topay.vue?vue&type=script&lang=js&"
import style0 from "./topay.vue?vue&type=style&index=0&id=18314800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18314800",
  null
  
)

export default component.exports