<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<el-button type="primary" size="mini">{{$t('sycm.actions.realTime')}}</el-button>
				</li>
				<li class="bar-item">
					<el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
						<span class="el-dropdown-link">
							{{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">
					<span>{{$t('sycm.ipoll.toPay')}}</span>
					<el-popover placement="bottom-start" width="200" trigger="hover">
						<i slot="reference" class="el-icon-question"></i>
						<div class="popover-text">{{$t('sycm.popover.toPay1')}}</div>
					</el-popover>
				</div>
			</div>
			<div class="section-main">
				<el-table :data="payData">
					<el-table-column :label="$t('sycm.tableHead.buyersInfo')">
						<template slot-scope="scope">
							{{ scope.row.user_name }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.potentialOrder')">
						<template slot-scope="scope">{{$t('sycm.common.total')}}{{ scope.row.order_num }}{{$t('sycm.unit.order')}}，{{$t('sycm.common.total')}}{{ scope.row.order_amount }}</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.latestOrderTime')">
						<template slot-scope="scope">{{ scope.row.add_time | parseTime() }}</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.orderStatus')">
						<template slot-scope="scope">
							{{ scope.row.order_status | statusFilter($t('sycm.options.orderStatus')) }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.actions')" type="expand">
						<div slot-scope="props" style="margin: 0 15px;">
							<el-table :data="props.row.orders">
								<el-table-column :label="$t('sycm.tableHead.orderBaby')">
									<template slot-scope="scope">
										<div class="goods-item" v-for="(goods,g) in scope.row.order_goods" :key="g">
											<img :src="goods.goods_image" alt="">
										</div>
									</template>
								</el-table-column>
								<el-table-column :label="$t('sycm.tableHead.orderMoney')" prop="order_amount" align="right"></el-table-column>
								<el-table-column :label="$t('sycm.tableHead.orderTime')" align="right">
									<template slot-scope="scope">
										{{ scope.row.add_time | parseTime() }}
									</template>
								</el-table-column>
								<el-table-column :label="$t('sycm.tableHead.orderId')" align="right">
									<template slot-scope="scope">
										<div>{{ scope.row.order_sn }}</div>
										<div><el-tag effect="dark" size="mini">{{$t('sycm.options.terminal')[0]}}</el-tag></div>
									</template>
								</el-table-column>
								<el-table-column :label="$t('sycm.tableHead.actions')" align="center" width="160px">
									<template slot-scope="scope">
										<a :href="$workBasePath+'/home/order/trade-platform/detail/'+scope.row.order_id" class="item-link" target="_bank">{{$t('sycm.actions.orderInfo')}}</a>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-table-column>
				</el-table>
				<div style="text-align: right;margin-top: 10px;">
					<el-pagination :total="pageTotal" :current-page="pageCurr" :page-size="pageSize"
						@current-change="handleCurrentChange" @size-change="handleSizeChange" layout="prev, pager, next"
						:prev-text="$t('sycm.actions.prevPage')" :next-text="$t('sycm.actions.nextPage')"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchIpollToPay } from "@/api/sycm"
export default {
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		},
		statusFilter(status, option) {
			const statusMap = { 0: 'info', 11: 'danger', 20: 'gray', 30: 'gray', 40: 'success' }
			if (option) {
				return option[status]
			}
			return statusMap[status]
		}
	},
	data() {
		return {
			terminalType: '',
			payData: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 10,
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData(){
			const loading = this.$loading()
			try {
				const params = {
					source: this.terminalType,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_siez: this.pageSize
				}
				const { data } = await fetchIpollToPay(params)
				this.payData = data.list
				this.pageTotal = parseInt(data.count)
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		handleCurrentChange(val) {
			this.pageCurr = val
			this.getData()
		},
		handleSizeChange(val) {
			this.pageCurr = val
			this.getData()
		},
		handleCommand(command) {
			this.terminalType = command
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;

				.el-popover__reference {
					margin-left: 6px;
					color: #999999;
					font-size: 14px;
					cursor: pointer;
				}
			}

			.barbox {
				span {
					margin-right: 10px;
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;

			.bar-item {
				margin-left: 10px;
			}
		}
	}
	::v-deep .el-table__expanded-cell {
		background-color: #fbfbfb!important;
		&:hover {
			background-color: #fbfbfb!important;
		}
	}
	
	.el-table {
		font-size: 12px;
	}
	.goods-item {
		width: 70px;
		height: 70px;
		display: inline-block;
		margin-right: 10px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.item-link {
		color: $--sycm-primary;
	}
}
</style>